<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import Preloader from "@/components/widgets/preloader";
  import {AuthJwtService} from '../../../app/service/auth/auth-jwt-service';

  import Repository from "../../../app/repository/repository-factory";

  const UserAccountRepository = Repository.get("UserAccountRepository");

/**
 * Contacts-grid component
 */
export default {
  components: { Layout, PageHeader, Preloader },
  data() {
    return {
      preloader: true,
      isGrantedEdit: false,
      userGridData: {},
      title: "Pracownicy fundacji",
      items: [
        {
          text: "Pracownicy fundacji",
          href: "/"
        },
        {
          text: "Lista",
          active: true
        }
      ]
    };
  },
  created() {
    this.isAdmin();
    this.isSuperAdmin();
    this.getAll();
  },
  methods: {
    isAdmin() {
      if (AuthJwtService.isGranted('ROLE_ADMIN') === true) {
        this.isGrantedEdit = true;
      }

    },
    isSuperAdmin() {
      if (AuthJwtService.isGranted('ROLE_SUPER_ADMIN') === true) {
        this.isGrantedEdit = true;
      }
    },
    getAll() {
        UserAccountRepository.getAll().then((response) => {
          this.userGridData = response.data;
          this.preloader = false;
        }).catch(error => {
          console.log(error);
        })

    }
  }
};
</script>

<template>
  <Layout>
    <preloader v-if="preloader"></preloader>

    <PageHeader :title="title" :items="items" />
    <div v-if="isGrantedEdit" class="row mb-2">
      <div class="col-sm-12 col-lg-12">
        <div class="text-sm-right">
          <b-link :to="{ name: 'Dodaj pracownika' }">
            <button type="button"
                    class="btn btn-primary waves-effect waves-light mb-2 mr-2">
              <i class="mdi mdi-plus mr-1"></i> Dodaj pracownika
            </button>
          </b-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-for="user in userGridData" :key="user.uuid" class="col-xl-3 col-sm-6">
        <div class="card text-center">
          <div class="card-body">
            <b-link :to="{ name: 'Profil pracownika', params: { id: user.uuid } }">
            <div v-if="user.profileImageUrl == ''" class="avatar-sm mx-auto mb-4">
              <span
                class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24"
              >{{user.firstName.substring(0,1)}}</span>
            </div>
            <div v-if="user.profileImageUrl !== ''" class="mb-4">
              <b-img-lazy :src="`${user.profileImageUrl}`" class="avatar-lg"
                          rounded="circle" width=100>
              </b-img-lazy>
            </div>
            <h5 class="font-size-15">
              <span class="text-dark">{{user.firstName}} {{user.lastName}}</span>
            </h5>
            <p class="text-muted">{{user.position}}</p>

              <p class="text-muted">
                <span class="badge badge-pill badge-soft-success font-size-12" :class=" {
                  'badge-soft-danger': user.isActive == false,
                  'badge-soft-success': user.isActive == true }">
                      {{ user.isActive ? 'Konto aktywne' : 'Konto nieaktywne' }}</span>
              </p>

            </b-link>
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <div class="flex-fill">
                <a v-b-tooltip.hover.top title="E-mail" :href="'mailto:' + user.email">
                  <i class="bx bx-mail-send"></i>
                </a>
              </div>
              <div class="flex-fill">
                <b-link :to="{ name: 'Profil pracownika', params: { id: user.uuid } }">
                  <i v-b-tooltip.hover.top title="Profil"  class="bx bx-user-circle"></i>
                </b-link>
              </div>
              <div v-if="isGrantedEdit" class="flex-fill">
                <b-link :to="{ name: 'Edycja pracownika', params: { id: user.uuid } }">
                  <i v-b-tooltip.hover.top title="Edycja"  class="bx bx-edit"></i>
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
